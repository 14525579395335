@import "https://fonts.googleapis.com/css2?family=Nunito:wght@200;400&display=swap";
.text-color {
  color: #171b2d;
}

.font-title {
  font-family: Nunito, sans-serif;
  font-size: 4rem;
  font-weight: 200;
}

.font-body {
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.font-button {
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.non-resizable {
  resize: none;
}

.hover-color {
  transition: all .15s ease-in;
}

.hover-color:hover {
  background-color: #c4efff;
}

.copy-animation {
  animation: copy .45s linear;
}

@keyframes copy {
  0% {
    background-color: #c4efff;
  }

  4% {
    color: #2c74ff;
    background-color: #fff;
  }

  16% {
    color: #2c74ff;
    background-color: #fff;
  }

  50% {
    background-color: #c4efff;
  }

  100% {
    background-color: none;
  }
}

.fade-in {
  animation: fadein .65s linear;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.w-copy {
  width: 5.5rem;
}

.h-textarea {
  height: 22.5rem;
}

/*# sourceMappingURL=index.86ee25ed.css.map */
